import React from 'react';
import { Typography } from '@material-ui/core';
import { useLegacyTelematicsDeviceContext } from 'pages/Unitlookup/stores/LegacyTelematicsDevice/LegacyTelematicsDeviceProvider';
import { GenericConfigurationProvider } from '../../stores/GenericConfiguration/GenericConfigurationProvider';
import GenericConfigButton from './GenericConfigButton';
import CustomerCommandDropdown from "./CustomerCommandDropdown";
import HALLinkGenericConfigButton from './HALLinkGenericConfigButton';
import { AnalyticsEvents } from '../../../../components/analytics/analyticsEvents';
import { useDeviceContext } from '../../stores/TelematicsDevice/TelematicsDeviceProvider';
import { withOktaAuth } from '@okta/okta-react';
import { IOktaContext } from '@okta/okta-react/bundles/types/OktaContext';
import {
  PredefinedCustomerCommand,
  TelematicsDeviceConfigurationApi
} from '../../stores/TelematicsDeviceConfiguration/TelematicsDeviceConfigurationApi';
import { LocationService } from '../../../../components/Location/LocationService';
import RebootButton from "../Reboot/RebootButton";
import FirmwareUpdateButton from "../FirmwareUpdate/FirmwareUpdateButton";
import InputConfiguration from "../InputConfiguration/InputConfiguration";
import HiltiServiceVanConfiguration from '../ConfigurationPublicApi/HiltiServiceVanConfiguration';
import Configuration, { ConfigurationEnum } from "../ConfigurationPublicApi/Configuration";
import {UnitInfo} from "../../stores/TelematicsDevice/ClassicUnitApi";
import {useAccessibilityContext} from "../../stores/AccessibilityProvider/AccessibilityProvider";

const GenericConfigOverview: React.FC<IOktaContext> = props => {
  const REFRESH_DEVICE_SETUP_MIN_FW_VERSION = 63.04;

  const {telematicsDevice, isFirmwareVersionEqualOrHigher} = useLegacyTelematicsDeviceContext();
  const {lastSavedCanProfileId, identity, isGriffinDevice, can1ProfileId } = useDeviceContext();
  const { accessibility } = useAccessibilityContext();

  const [canRefreshDeviceSetup, setCanRefreshDeviceSetup] = React.useState(false);

  const [customerCommands, setCustomerCommands] = React.useState<PredefinedCustomerCommand[]>([]);
  const [customerCommandsForProfile, setCustomerCommandsForProfile] = React.useState<PredefinedCustomerCommand[]>([]);
  const [customerCommandsForDropdown, setCustomerCommandsForDropdown] = React.useState<PredefinedCustomerCommand[][]>([]);

  React.useEffect(() => {
    if (telematicsDevice) {
      setCanRefreshDeviceSetup(
          !LocationService.isManitou() && isFirmwareVersionEqualOrHigher(REFRESH_DEVICE_SETUP_MIN_FW_VERSION)
      );
      TelematicsDeviceConfigurationApi.getPredefinedCustomerCommands(
        props.authState?.accessToken
      ).then((commands: PredefinedCustomerCommand[]) => {
        setCustomerCommands(commands);
      });
    } else {
      setCustomerCommands([]);
    }
  }, [telematicsDevice]);

  React.useEffect(() => {
    setCustomerCommandsForProfile(customerCommands.filter(c => !c.canProfileId || c.canProfileId.toString() === can1ProfileId));
    setCustomerCommandsForDropdown(customerCommands.filter(c => c.groupId)
        .reduce((commandGroup: PredefinedCustomerCommand[][], command) => {
          (commandGroup[command.groupId] = commandGroup[command.groupId] || []).push(command);
          return commandGroup;
        }, [])
    );
  }, [can1ProfileId, customerCommands]);

  const isValidFirmware = (command: PredefinedCustomerCommand) => {
    return telematicsDevice &&
        telematicsDevice.firmwareVersion >= command.minimumFirmwareVersion &&
        (command.maximumFirmwareVersion ? telematicsDevice.firmwareVersion <= command.maximumFirmwareVersion : true) ? true : false;
  }

  const isCanProfileSaved = (command: PredefinedCustomerCommand) => {
    return command.canProfileId ? command.canProfileId.toString() === lastSavedCanProfileId : true;
  }

  return telematicsDevice &&
    (
        !LocationService.isManitou() ||
        GenericConfigurationProvider.hasGenericConfiguration(telematicsDevice) ||
        GenericConfigurationProvider.hasSkyjackBmsConfiguration(telematicsDevice) ||
        customerCommandsForProfile.length > 0) ? (
    <>

      <Typography variant="h5" gutterBottom>
        Actions
      </Typography>


      {accessibility?.reboot.visible && <RebootButton />}
      {accessibility?.firmwareUpdate.visible && <FirmwareUpdateButton /> }
      {accessibility?.inputConfig.visible && <InputConfiguration /> }

      <HALLinkGenericConfigButton
        visible={accessibility?.calibrateTiltSensor.visible === true && GenericConfigurationProvider.hasGenericConfiguration(
          telematicsDevice
        )}
        configName="generic-configuration"
        buttonLabel="Calibrate tilt sensor"
        confirmLabel="Are you sure you want to calibrate the tilt sensor?"
        analyticsEvent={AnalyticsEvents.CalibrateTiltSensor}
        performConfiguration={GenericConfigurationProvider.calibrateTiltSensor}
      />

      {accessibility?.legacyGenericConfigurations.visible && customerCommands && customerCommandsForProfile
          .filter(c => !c.groupId)
          .sort((a, b) =>
            a.sortOrder > b.sortOrder ? 1 : b.sortOrder > a.sortOrder ? -1 : 0
          )
          .map(command => (
            <GenericConfigButton
              key={command.commandId}
              visible={isValidFirmware(command)}
              buttonLabel={command.label}
              confirmLabel={`Are you sure you want to execute the command: ${
                command.label
              }?`}
              analyticsEvent={AnalyticsEvents.CustomerCommand}
              performConfiguration={() => {
                return TelematicsDeviceConfigurationApi.executePredefinedCustomerCommand(
                  props.authState?.accessToken,
                  telematicsDevice.serialNumber,
                  command.commandId
                );
              }}
              prerequisiteFulfilled={(unitInfo: UnitInfo) => {
                  return isValidFirmware(command) && isCanProfileSaved(command);
                }
              }
              prerequisiteFailureLabel={
                !isValidFirmware(command) && command.maximumFirmwareVersion ? `Requires firmware version between ${command.minimumFirmwareVersion} and ${command.maximumFirmwareVersion}`
                  : !isValidFirmware(command) ? `Requires firmware version ${command.minimumFirmwareVersion} or higher`
                    : !isCanProfileSaved(command) ? 'Selected can profile has not been saved. Click the Save button before executing this action'
                      : 'Click the Save button before executing this action'
              }
            />
          ))}

      {!isGriffinDevice(identity) && (
        <>
          <HiltiServiceVanConfiguration />
          <Configuration authState={props.authState} configuration={ConfigurationEnum.HILTI_BLE_SCANNING_CONFIGURATION_NAME} />
        </>
      )}

      {accessibility?.legacyCustomerCommands.visible && customerCommandsForDropdown.map(commands => (
          commands.length > 0 &&
              <CustomerCommandDropdown
                  analyticsEvent={AnalyticsEvents.CustomerCommand}
                  accessToken={props.authState?.accessToken}
                  serialNumber={telematicsDevice.serialNumber}
                  firmwareVersion={telematicsDevice.firmwareVersion}
                  lastSavedCanProfileId={lastSavedCanProfileId}
                  commands={commands.sort((a, b) =>
                      a.sortOrder > b.sortOrder ? 1 : b.sortOrder > a.sortOrder ? -1 : 0
                  )}
                  dropdownLabel={commands[0].groupLabel}
          />
      ))}
    </>
  ) : null;
};

export default withOktaAuth(GenericConfigOverview);
