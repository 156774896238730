import { getEnviroment } from '../../../../env';
import axios from 'axios';
import { defaultAxiosOptions } from '../common/DefaultAxiosOptions';
import { AccessToken } from '@okta/okta-auth-js';

const assetEndpoint = getEnviroment().endpoints.asset;

interface AssetNote {
  value: string;
}

const getNote = (accessToken: AccessToken | undefined,
                 assetId: string) => {
  return axios
    .post<AssetNote[]>(
      `${assetEndpoint}/notes/query`,
      { assetIds: [assetId] },
      defaultAxiosOptions(accessToken)
    )
    .then(response => response.data)
    .then(assetNotes => assetNotes.length > 0 ? assetNotes[0].value : "")
    .catch(() => {
      throw new Error(`An Unexpected error occurred while trying to fetch note for asset: ${assetId}`)
    });
}

const updateNote = (accessToken: AccessToken | undefined,
                    assetId: string,
                    note: string) => {
  return axios
    .patch<AssetNote[]>(`${assetEndpoint}/note`,
      {
        identifier: {
          type: 'ASSET_ID',
          value: assetId
        },
        note: note
      },
      defaultAxiosOptions(accessToken)
    )
    .catch(() => {
      throw new Error(`An Unexpected error occurred while trying to update note for asset: ${assetId}`)
    });
};

export const AssetApi = {
  getNote,
  updateNote
}