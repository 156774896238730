import { AuthState } from "@okta/okta-auth-js";
import { DeviceStateApi, OperatingModeState, OperatingModeType } from "../TelematicsDevice/DeviceStateApi";

export const parseOperatingModeToState = (
  mode: OperatingModeType) : OperatingModeState => {

  return {
    operatingMode: {
      mode
    }
  };
}

export const saveOperatingMode = (
  authState: AuthState | null,
  deviceId: string,
  mode: OperatingModeType
  ) => {

  const desiredState = parseOperatingModeToState(mode);

  if( desiredState)
  {
    return DeviceStateApi.updateState(authState?.accessToken, deviceId, {...desiredState});
  }

  return Promise.resolve();
}