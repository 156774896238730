import React from 'react';
import { TextField } from '@material-ui/core';
import { useDeviceContext } from 'pages/Unitlookup/stores/TelematicsDevice/TelematicsDeviceProvider';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import './UnitInputs.scss';
import {useAccessibilityContext} from "../../stores/AccessibilityProvider/AccessibilityProvider";

interface InputRow {
  title: string;
  inputValue: boolean | undefined;
  analogInput: number | undefined;
  nameValue: string | undefined;
  changeHandler: ((value: string) => void) | undefined;
  visible: boolean | undefined;
}

export const UnitInputs = () => {
  const {
    unitInfo,
    telemetry,
    input1Name,
    input2Name,
    input3Name,
    input4Name,
    input5Name,
    input6Name,
    setInput1Name,
    setInput2Name,
    setInput3Name,
    setInput4Name,
    setInput5Name,
    setInput6Name,
  } = useDeviceContext();

  const {accessibility} = useAccessibilityContext();

  const inputFields: Array<InputRow> = [];

  inputFields.push(
    {
      title: 'Input 1',
      inputValue: telemetry.io.input1.active,
      analogInput: telemetry.io.input1.voltage,
      nameValue: input1Name,
      changeHandler: setInput1Name,
      visible: true,
    },
    {
      title: 'Input 2',
      inputValue: telemetry.io.input2.active,
      analogInput: telemetry.io.input2.voltage,
      nameValue: input2Name,
      changeHandler: setInput2Name,
      visible: true,
    },
    {
      title: 'Input 3',
      inputValue: telemetry.io.input3.active,
      analogInput: telemetry.io.input3.voltage,
      nameValue: input3Name,
      changeHandler: setInput3Name,
      visible: true,
    },
    {
      title: 'Input 4',
      inputValue: telemetry.io.input4.active,
      analogInput: telemetry.io.input4.voltage,
      nameValue: input4Name,
      changeHandler: setInput4Name,
      visible: true,
    },
    {
      title: 'Input 5',
      inputValue: telemetry.io.input5?.active,
      analogInput: telemetry.io.input5?.voltage,
      nameValue: input5Name,
      changeHandler: setInput5Name,
      visible: accessibility?.input5Input6.visible,
    },
    {
      title: 'Input 6',
      inputValue: telemetry.io.input6?.active,
      analogInput: telemetry.io.input6?.voltage,
      nameValue: input6Name,
      changeHandler: setInput6Name,
      visible: accessibility?.input5Input6.visible,
    }
  );

  return (
    <>
      {inputFields.map(inputField => (
        inputField.visible && (
          <dl key={'value_label_' + inputField.title} className="staticSection">
            <dt>{inputField.nameValue || inputField.title}:</dt>
            <dd>
              {inputField.inputValue ? 'On' : 'Off'}{' '}
              {inputField.analogInput
                ? formatAsVoltage(inputField.analogInput)
                : ''}
            </dd>
          </dl>
        )
      ))}
      <FormControl variant="outlined" fullWidth margin="normal">
        {inputFields.map(inputField => (
          (inputField.changeHandler && inputField.visible) && (
            <div key={'input_' + inputField.title}>
              <TextField
                disabled={accessibility?.inputNames.readonly}
                name={inputField.title}
                label={inputField.title}
                fullWidth
                variant="outlined"
                value={inputField.nameValue || ''}
                margin="normal"
                onChange={evt => {
                  inputField.changeHandler &&
                  inputField.changeHandler(evt.target.value);
                }}
              />
              {accessibility?.inputNames.readonly && (
                <FormHelperText>Can only be changed by owner</FormHelperText>
              )}
            </div>
          )
        ))}
      </FormControl>
    </>
  );
};

const formatAsVoltage = (input: number) => `${(input / 1000 ).toFixed(2)}V`;
