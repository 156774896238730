import axios from 'axios';
import { getEnviroment } from 'env';
import { defaultAxiosOptions } from '../common/DefaultAxiosOptions';
import {AccessToken} from "@okta/okta-auth-js";

const unitInfoEndpoint = getEnviroment().endpoints.unitInfo;

export type BaseUnitInfo = {
  phoneNumber?: string;
  input1Name?: string;
  input2Name?: string;
  input3Name?: string;
  input4Name?: string;
  name?: string;
  totalKm?: number;
  preKm?: number;
  totalRun1?: number;
  preRun1?: number
  categoryId?: string;
  referenceNumber?: string;
  note?: string;
  isOwn?: boolean;
  mtecHasCanSupport?: boolean;
  inFilt?: boolean;
}

export type UnitInfo = BaseUnitInfo & {id: string}

function getUnitInfo(
  accessToken: AccessToken | undefined,
  serialNumber: string
): Promise<UnitInfo> {
  return axios
    .get<any>(
      `${unitInfoEndpoint}?OmitLocation=true&includeinfilt=true&SerialNumber=${serialNumber}`,
      defaultAxiosOptions(accessToken)
    )
    .then(restResult => {
      if (200 <= restResult.status && restResult.status < 300) {
        return restResult.data.list[0] as UnitInfo;
      } else {
        console.error('Unexpected response: ', restResult);
        throw new Error(
          `An Unexpected error occured while trying to fetch unit '${serialNumber}`
        );
      }
    })
    .catch(error => {
      if (!error.response) {
        throw new Error(
          `An Unexpected error occured while trying to fetch unit '${serialNumber} - server returned no data`
        );
      } else {
        switch (error.response.data.responseStatus.errorCode) {
          case 'UnitNotFound':
            throw new Error(
              `The requested unit ('${serialNumber}') was not found`
            );
          default:
            throw new Error(
              `An Unexpected error occured while trying to fetch unit '${serialNumber}`
            );
        }
      }
    });
}

export const ClassicUnitApi = { getUnitInfo };
