import { FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import { withOktaAuth } from '@okta/okta-react';
import { IOktaContext } from '@okta/okta-react/bundles/types/OktaContext';
import { useAccessibilityContext } from 'pages/Unitlookup/stores/AccessibilityProvider/AccessibilityProvider';
import { Gateway, operatingModeType } from 'pages/Unitlookup/stores/TelematicsDevice/DeviceStateApi';
import { useDeviceContext } from 'pages/Unitlookup/stores/TelematicsDevice/TelematicsDeviceProvider';
import React from 'react';
import { StateStatus } from '../DeviceState/StateStatus';
import DeviceIdentity from '../DeviceIdentity/DeviceIdentity';

const OperatingMode: React.FC<IOktaContext> = props => {
  const { accessibility } = useAccessibilityContext();
  const { state, identity, setOperatingMode, isGriffinDevice } = useDeviceContext();

  const getAccessReasonLabel = () => {
    if (accessibility?.operatingMode.readonly) {
      switch (accessibility.operatingMode.accessReason) {
        case 'NOT_OWNER_ACCOUNT':
          return 'Only owners and admins of the device may change the Operating Mode';
        case 'FIRMWARE_NOT_SUPPORTED':
          return 'A firmware version of 63.08 or higher is required to change the Operating Mode';
        default:
          return undefined;
      }
    }
    return undefined;
  }

  const TU600_OPERATING_MODES = ['Normal', 'Stock', 'Transport'];
  const TU700_OPERATING_MODES = TU600_OPERATING_MODES.concat(['Restricted']);

  return (
    <div className="stateWrapper">
      <FormControl
        variant="outlined"
        fullWidth
        margin="normal"
        disabled={accessibility?.operatingMode.readonly}
      >
        <InputLabel htmlFor="operating-mode">Operating Mode</InputLabel>
        <Select
          defaultValue={state.desired.operatingMode?.mode ?? "normal"}
          input={
            <OutlinedInput
              labelWidth={120}
              name="operatingMode"
              id="operating-mode"
            />
          }
          onChange={(evt: any) => setOperatingMode(evt.target.value)}
        >
          {(isGriffinDevice(identity) ? TU700_OPERATING_MODES : TU600_OPERATING_MODES).map(mode => (
            <MenuItem key={mode.toLowerCase()} value={mode.toLowerCase()}>
              {mode}
            </MenuItem>
          ))}
        </Select>
        {getAccessReasonLabel() && <FormHelperText>{getAccessReasonLabel()}</FormHelperText>}
      </FormControl>
      <StateStatus
          getIdentityFromDesiredState={() => state.desired.operatingMode?.mode?.toString()}
          getIdentityFromReportedState={() => state.reported.operatingMode?.mode?.toString()}
          getGatewayUsedFromDesiredMetadataState={() => Gateway.IRIS}
      />
    </div>
  );
};

export default withOktaAuth(OperatingMode);
