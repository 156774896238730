import React from 'react';
import {useDeviceContext} from "../TelematicsDevice/TelematicsDeviceProvider";
import {useLegacyTelematicsDeviceContext} from "../LegacyTelematicsDevice/LegacyTelematicsDeviceProvider";
import {GatewayStateEnum} from "../LegacyTelematicsDevice/LegacyTelematicsDeviceApi";
import {LocationService} from "../../../../components/Location/LocationService";
import {getUserInfo} from "../../../Login/AuthenticationService";
import {useOktaAuth} from "@okta/okta-react";
import {AccessControlMode} from "../../components/DeviceAccessControl/DeviceAccessControl";
import {isFirmwareGreaterThanOrEqualTo} from "../TelematicsDevice/FirmwareComparison";
import { ConfigurationAccessType, DigitalKeyModeEnum} from "../TelematicsDevice/DeviceStateApi";
import { DeviceTypeEnum } from '../TelematicsDevice/DeviceTypeEnum';
import { identify } from 'amplitude-js';

export type Visibility = {
  visible: boolean;
  readonly: boolean;
}

export type VisibilityExtended = {
  access?: ConfigurationAccessType;
  accessReason?: string;
} & Visibility

export const dataSourceType = {
  Classic: "CLASSIC",
  Iris: "IRIS",
} as const;
export type DataSourceType = (typeof dataSourceType)[keyof typeof dataSourceType];

export type MigrationStrategy = {
  readSource: DataSourceType;
  writeSource: DataSourceType;
}

export type Accessibility = {
  outputMode: Visibility & MigrationStrategy;
  inputFiltering: Visibility & MigrationStrategy;
  notes: Visibility & MigrationStrategy;
  categories: Visibility & MigrationStrategy;
  groups: Visibility & MigrationStrategy;
  classicAccessControl: Visibility & MigrationStrategy;
  digitalKey: VisibilityExtended;
  relayConfiguration: VisibilityExtended;
  canProfileList: MigrationStrategy;
  inputNames: Visibility & MigrationStrategy;
  powerMode: Visibility;
  reboot: Visibility;
  firmwareUpdate: Visibility;
  calibrateTiltSensor: Visibility;
  hiltiServiceVan: {};
  hiltiBluetoothScanning: {};
  legacyGenericConfigurations: Visibility;
  legacyCustomerCommands: Visibility;
  inputConfig: Visibility;
  canProfile1: VisibilityExtended;
  canProfile2: VisibilityExtended;
  modbusProfile: Visibility;
  replace: Visibility;
  input5Input6: Visibility;
  activityDetection: Visibility;
  can1ActivityDetection: Visibility;
  can2ActivityDetection: Visibility;
  operatingMode: VisibilityExtended;
}

type AccessibilityContextValue = {
  accessibility?: Accessibility;
  setAccessibility: (accessibility: Accessibility) => void;
};

const AccessibilityContext = React.createContext<
  AccessibilityContextValue | undefined
>(undefined);

type AccessibilityProviderProps = {
  children: React.ReactNode;
};

const AccessibilityProvider = (props: AccessibilityProviderProps) => {
  const [accessibility, setAccessibility] = React.useState<Accessibility>();
  let deviceContext = useDeviceContext();
  const legacyTelematicsDevice = useLegacyTelematicsDeviceContext();
  const { authState } = useOktaAuth();

  const value: AccessibilityContextValue = React.useMemo(
    () => ({
      accessibility: accessibility,
      setAccessibility: setAccessibility
    }),
    [accessibility]
  );

  const isOnIris = () : boolean => {
    return isTU700() || legacyTelematicsDevice.telematicsDevice?.gatewayState === GatewayStateEnum.Iris;
  }

  const hasOutput1ThroughTlvSupport = () : boolean => {
    return isTU700() || deviceContext.hasTlvSupport;
  }

  const hasInputFilteringThroughTlvSupport = () : boolean => {
    return isTU700() || deviceContext.hasTlvSupport;
  }

  const hasInputConfigThroughTlvSupport = () : boolean => {
    return isTU700() || isFirmwareGreaterThanOrEqualTo(legacyTelematicsDevice.telematicsDevice?.firmwareVersion, 63,4);
  }

  const hasDigitalKeyThroughTlvSupport = () : boolean => {
    return isFirmwareGreaterThanOrEqualTo(legacyTelematicsDevice.telematicsDevice?.firmwareVersion, 63,6);
  }

  const hasAccessControl = () : boolean => {
    return deviceContext.state.desired.accessControl?.mode === DigitalKeyModeEnum.UNLOCKED_FOR_ALL_FIXED ||
        deviceContext.state.desired.accessControl?.mode === DigitalKeyModeEnum.UNLOCKED_FOR_ALL
  }

  const hasClassicAccessControl = () : boolean => {
    return legacyTelematicsDevice.telematicsDevice?.accessControlMode !== undefined && legacyTelematicsDevice.telematicsDevice?.accessControlMode !== AccessControlMode.doNotUseAccessKeys
  }

  const hasCanSupport = () : boolean => {
    return isTU700() || deviceContext.unitInfo.mtecHasCanSupport === true;
  }

  const hasPhoneNumber = () : boolean => {
    return deviceContext.unitInfo?.phoneNumber !== undefined;
  }

  const hasOperatingModeSupport = () : boolean => {
    return isTU700() || isFirmwareGreaterThanOrEqualTo(legacyTelematicsDevice.telematicsDevice?.firmwareVersion, 63, 8);
  }

  const getOperatingModeAccessReason = () : string | undefined => {
    if( !isTrackunitUser() && !isUserOnOwnerAccount() ) {
      return "NOT_OWNER_ACCOUNT";
    }

    if( !isTU700() && !isFirmwareGreaterThanOrEqualTo(legacyTelematicsDevice.telematicsDevice?.firmwareVersion, 63, 8)) {
      return "FIRMWARE_NOT_SUPPORTED";
    }

    return undefined;
  }

  const isTU700 = () : boolean => {
    return deviceContext.identity.deviceType === DeviceTypeEnum.TU700 || deviceContext.identity.deviceType === DeviceTypeEnum.GRIFFIN;
  }

  const isRawDevice = () : boolean => {
    return deviceContext.isRawDevice(deviceContext.identity);
  }

  const isAccessManagementEnabled = () : boolean => {
    return (deviceContext.stateDocumentation?.accessControl?.readonly ?? false) && deviceContext.stateDocumentation?.accessControl?.accessReason === "ACCESS_MANAGEMENT_ENABLED";
  }

  const isManitouBranded = () : boolean => {
    return LocationService.isManitou();
  }

  const isUserOnOwnerAccount = () : boolean => {
    // Access to all units AND user on unit owner account
    return deviceContext.unitInfo.isOwn === true;
  }

  const isTrackunitUser = () : boolean => {
    return getUserInfo(authState?.accessToken)?.customerId === 3;
  }

  const isCANReadonly = () : boolean => {
    return deviceContext.stateDocumentation?.canbus?.readonly === true;
  }

  React.useEffect(() => {
    /*
      Classic Access Level: https://github.com/Trackunit/SafeTrackV5/blob/master/Documents/FromJacob/UserAccessRights.txt
    */

    if( deviceContext.identity && deviceContext.unitInfo && legacyTelematicsDevice) {
      setAccessibility({
        // Old devices still uses MT Commands for this. That's why it's a mix between legacy and iris
        // The device will reject this configuration if it has an Access Control setup
        outputMode: {
          readSource: isOnIris() && hasOutput1ThroughTlvSupport() ? "IRIS" : "CLASSIC",
          writeSource: hasOutput1ThroughTlvSupport() ? "IRIS" : "CLASSIC",
          visible: true,
          // Old check: !deviceContext.unitInfo.canEditOutput1
          readonly: hasAccessControl() || hasClassicAccessControl() || !hasPhoneNumber() || !(isUserOnOwnerAccount() || isTrackunitUser())
        },
        // Old devices still uses MT Commands for this. That's why it's a mix between legacy and iris
        inputFiltering: {
          readSource: isOnIris() && hasInputFilteringThroughTlvSupport() ? "IRIS" : "CLASSIC",
          writeSource: hasInputFilteringThroughTlvSupport() ? "IRIS" : "CLASSIC",
          // Logic from classic is to set inFilt to NULL if CanEdit is not true. The CanEdit comes from the CustomerUnit table
          // And you need AccessLevel 1, 2, 6 or 8
          visible: isTU700() || ( deviceContext.unitInfo.inFilt !== undefined && deviceContext.unitInfo.inFilt !== null ),
          readonly: !(isUserOnOwnerAccount() || isTrackunitUser())
        },
        // Will die with Classic.
        notes: {
          readSource: "CLASSIC",
          writeSource: "CLASSIC",
          visible: true,
          readonly: false
        },
        // Will die with Classic.
        categories: {
          readSource: "CLASSIC",
          writeSource: "CLASSIC",
          visible: true,
          readonly: false
        },
        // Re-design. A drop-down box with chechboxes is no longer suitable. Customers might have thousands of groups.
        // UI could be the same as in Manager. Or we could remove the option from Verify
        groups: {
          readSource: "CLASSIC",
          writeSource: "CLASSIC",
          visible: true,
          readonly: false
        },
        // Cannot be migrated. Will die with Classic
        classicAccessControl: {
          readSource: "CLASSIC",
          writeSource: "CLASSIC",
          visible: !isTU700() && !isAccessManagementEnabled(),
          // in Classic, this also requires the setting "Access to key access control"
          // Currently the device-access-control-api is used for setting this and the above check is not included. It is requiring AccessLevel 1, 2, 6 and 8
          readonly: !isUserOnOwnerAccount()
        },
        digitalKey: {
          visible: isTU700() || hasDigitalKeyThroughTlvSupport(),
          readonly: hasClassicAccessControl() || deviceContext.stateDocumentation?.accessControl?.readonly || isAccessManagementEnabled() || !(isTrackunitUser() || isUserOnOwnerAccount()),
          accessReason: hasClassicAccessControl() ? "HAS_CLASSIC_ACCESS_CONTROL" : deviceContext.stateDocumentation?.accessControl?.accessReason ? deviceContext.stateDocumentation?.accessControl?.accessReason : undefined
        },
        relayConfiguration: {
          visible: isAccessManagementEnabled() || isTrackunitUser()  ,
          readonly: !((isTrackunitUser() || isUserOnOwnerAccount()) && isOnIris() && (isTU700() || hasDigitalKeyThroughTlvSupport())),
          accessReason: !(isTrackunitUser() || isUserOnOwnerAccount()) ? "NOT_OWNER_ACCOUNT" : undefined,
        },
        // Ready to migrate. List is also available from the state-documentation endpoint
        canProfileList: {
          readSource: "CLASSIC",
          writeSource: "CLASSIC",
        },
        // Will be migrated to telemetry-interpretation
        inputNames: {
          readSource: "CLASSIC",
          writeSource: "CLASSIC",
          visible: true,
          // In Classsic this also requires the "Update rights under the module 'Settings'" flag
          readonly: !(isTrackunitUser() || isUserOnOwnerAccount())
        },
        // Uses legacy configuration from the Telematics Device API
        powerMode: {
          // There's an additional check in the Component: Only show if it has been enabled through Subscription
          visible: !isTU700() && isManitouBranded(),
          readonly: false
        },
        reboot: {
          visible: !isManitouBranded() && !isTU700(),
          readonly: false
        },
        firmwareUpdate: {
          visible: !isManitouBranded(),
          readonly: false
        },
        calibrateTiltSensor: {
          visible: !isManitouBranded() && !isTU700(),
          readonly: false
        },
        // Should use Device State
        inputConfig: {
          visible: !isManitouBranded() && hasInputConfigThroughTlvSupport(),
          readonly: !(isTrackunitUser() || isUserOnOwnerAccount())
        },
        hiltiServiceVan: {
          // TODO
        },
        hiltiBluetoothScanning: {
          // TODO
        },
        legacyGenericConfigurations: {
          visible: !isManitouBranded() && !isTU700(),
          readonly: false
        },
        legacyCustomerCommands: {
          visible: !isManitouBranded() && !isTU700(),
          readonly: false
        },
        canProfile1: {
          visible: true,
          // Additional check in Component: set to readonly if list of CAN profiles cannot be fetched from Legacy
          readonly: isCANReadonly() || !hasCanSupport(),
          access: deviceContext.stateDocumentation?.canbus?.access,
          accessReason: deviceContext.stateDocumentation?.canbus?.accessReason
        },
        canProfile2: {
          visible: isTU700(),
          // Additional check in Component: set to readonly if list of CAN profiles cannot be fetched from Legacy
          readonly: isCANReadonly()
        },
        modbusProfile: {
          visible: isTU700(),
          readonly: !isTU700() || !(isTrackunitUser() || isUserOnOwnerAccount())
        },
        replace: {
          visible: true,
          readonly: false
        },
        input5Input6: {
          visible: isTU700(),
          readonly: true
        },
        activityDetection: {
          visible: !isTU700(),
          readonly: true},
        can1ActivityDetection: {
          visible: isTU700(),
          readonly: true},
        can2ActivityDetection: {
          visible: isTU700(),
          readonly: true},
        operatingMode: {
          visible: !isManitouBranded() && isRawDevice(),
          readonly: !hasOperatingModeSupport() || !(isTrackunitUser() || isUserOnOwnerAccount()),
          access: !hasOperatingModeSupport() || !(isTrackunitUser() || isUserOnOwnerAccount()) ? "VIEW" : "EDIT", // TODO: Replace by rule from state-documentation when it's available
          accessReason: getOperatingModeAccessReason()
        }});
    }

  }, [deviceContext.identity, deviceContext.unitInfo, deviceContext.stateDocumentation, legacyTelematicsDevice.telematicsDevice])

  // Useful for debugging
/*
  React.useEffect(() => {
    if( deviceContext.identity) {
      console.log('Accessibility', accessibility);
      console.log('Accessibility-raw', {
        isOnIris: isOnIris(),
        hasOutput1ThroughTlvSupport: hasOutput1ThroughTlvSupport(),
        hasInputFilteringThroughTlvSupport: hasInputFilteringThroughTlvSupport(),
        hasClassicAccessControl: hasClassicAccessControl(),
        hasPhoneNumber: hasPhoneNumber(),
        isTU700: isTU700(),
        isManitouBranded: isManitouBranded(),
        isUserOnOwnerAccount: isUserOnOwnerAccount(),
        isTrackunitUser: isTrackunitUser(),
        hasCanSupport: hasCanSupport(),
        isAccessControlReadonly: deviceContext.stateDocumentation?.accessControl?.readonly
        })
    }

  }, [accessibility])
*/

  return <AccessibilityContext.Provider value={value} {...props} />;
};

const useAccessibilityContext = () => {
  const context = React.useContext(AccessibilityContext);

  if (!context) {
    throw new Error(
      'useAccessibilityContext must be used within an AccessibilityProvider'
    );
  }

  return {
    accessibility: context.accessibility
  };
};

export { AccessibilityProvider, useAccessibilityContext };
